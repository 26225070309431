<template>
  <Portlet
    title="Freshdesk Ticket List"
    class="freshdesk"
    icon="ticket-alt"
  >
    <template slot="buttons">
      <font-awesome-icon
        :class="['alt-pointer color-primary mr-2', { 'fa-spin' : loading}]"
        icon="sync-alt"
        @click="getInstallationTickets()"
      />
    </template>
    <div class="freshdeskList">
      <LoadingPlaceholder v-if="loading" />
    
      <template v-else>
        <Grid
          :ref="kgm_ref"
          :style="{height: 'auto'}"
          :data-items="kgm_computedGridItems(freshdeskTickets)"
          :columns="kgm_responsiveColumns()"
          :filterable="true"
          :filter="kgm_filter"
          :pageable="kgm_pagable"
          :page-size="kgm_take"
          :skip="kgm_skip"
          :take="kgm_take"
          :total="kgm_allGridItems(freshdeskTickets)"
          :sortable="{
            allowUnsort: kgm_allowUnsort,
            mode: kgm_sortMode
          }"
          :sort="kgm_sort"
          selected-field="selected"
          @rowclick="kgm_gridOnRowClick"
          @filterchange="kgm_gridFilterChange"
          @pagechange="kgm_gridPageChange"
          @sortchange="kgm_gridSortChange"
        >
          <template
            slot="idLink"
            slot-scope="{props}"
          >
            <td class="alt-primary-color">
              <a
                :href="props.dataItem.internal_link"
                target="_blank"
              >{{ kgm_getNestedValue(props.field, props.dataItem) }}</a>
            </td>
          </template>
          <template
            slot="subjectLink"
            slot-scope="{props}"
          >
            <td
              v-if="props.dataItem.custom_fields.cf_publicticketurl"
              class="alt-primary-color"
            >
              <a
                :href="props.dataItem.custom_fields.cf_publicticketurl"
                target="_blank"
              >{{ kgm_getNestedValue(props.field, props.dataItem) }}</a>
            </td>
            <td v-else>
              {{ kgm_getNestedValue(props.field, props.dataItem) }}
            </td>
          </template>
          <template
            slot="createdAtCell"
            slot-scope="{props}"
          >
            <td>
              {{ dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem)) }}
            </td>
          </template>
          <div
            slot="idFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="idFilter"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter an ID"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('idFilter', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="ticketSubjectFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="ticketSubjectFilter"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a subject"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('ticketSubjectFilter', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="statusFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="statusFilter"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a status"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('statusFilter', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="createdFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="createdFilter"
              :value="props.value"
              type="date"
              class="form-control mr-2 rounded-right"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('createdFilter', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="agentFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="agentFilter"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter an agent"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('agentFilter', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
        </Grid>
      </template>
    </div>
  </Portlet>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "FreshdeskList",
  mixins: [
    kendoGridMixin,
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      freshdeskTickets: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'key',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "key",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'id',
          filterable: true,
          filter: 'text',
          title: 'Id',
          filterCell: 'idFilter',
          format: '#{0}',
          cell: 'idLink',
          width: '200px',
          hideOn: ['xsDown']
        },
        {
          field: 'id',
          filterable: true,
          filter: 'text',
          title: 'Id',
          filterCell: 'idFilter',
          format: '#{0}',
          cell: 'idLink',
          hideOn: ['smUp']
        },
        {
          field: 'subject',
          filterable: true,
          filter: 'text',
          title: this.$t('freshdesk.subject'),
          filterCell: 'ticketSubjectFilter',
          cell: 'subjectLink',
        },
        {
          field: 'statusText',
          filterable: true,
          filter: 'text',
          title: 'Status',
          filterCell: 'statusFilter',
          width: '200px',
          hideOn: ['smDown'],
        },
        {
          field: 'created_at',
          filterable: true,
          filter: 'date',
          title: this.$t('freshdesk.createdAt'),
          filterCell: 'createdFilter',
          width: '200px',
          cell: 'createdAtCell',
          hideOn: ['mdDown'],
        },
        {
          field: 'agentName',
          filterable: true,
          filter: 'text',
          title: 'Agent',
          filterCell: 'agentFilter',
          hideOn: ['lgDown']
        }
      ]
    }
  },
  created () {
    this.getInstallationTickets();
  },
  methods: {
    //api calls
    getInstallationTickets () {
      this.loading = true;
      this.axios.get(`/Freshdesk/GetTickets?installationId=${ this.installationId }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.freshdeskTickets = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
<style>
.freshdeskList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Portlet',{staticClass:"freshdesk",attrs:{"title":"Freshdesk Ticket List","icon":"ticket-alt"}},[_c('template',{slot:"buttons"},[_c('font-awesome-icon',{class:['alt-pointer color-primary mr-2', { 'fa-spin' : _vm.loading}],attrs:{"icon":"sync-alt"},on:{"click":function($event){return _vm.getInstallationTickets()}}})],1),_c('div',{staticClass:"freshdeskList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.freshdeskTickets),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.freshdeskTickets),"sortable":{
          allowUnsort: _vm.kgm_allowUnsort,
          mode: _vm.kgm_sortMode
        },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"idLink",fn:function(ref){
        var props = ref.props;
return [_c('td',{staticClass:"alt-primary-color"},[_c('a',{attrs:{"href":props.dataItem.internal_link,"target":"_blank"}},[_vm._v(_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem)))])])]}},{key:"subjectLink",fn:function(ref){
        var props = ref.props;
return [(props.dataItem.custom_fields.cf_publicticketurl)?_c('td',{staticClass:"alt-primary-color"},[_c('a',{attrs:{"href":props.dataItem.custom_fields.cf_publicticketurl,"target":"_blank"}},[_vm._v(_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem)))])]):_c('td',[_vm._v(" "+_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem))+" ")])]}},{key:"createdAtCell",fn:function(ref){
        var props = ref.props;
return [_c('td',[_vm._v(" "+_vm._s(_vm.dateTime_dateTime(_vm.kgm_getNestedValue(props.field, props.dataItem)))+" ")])]}},{key:"idFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"idFilter",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter an ID"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('idFilter', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"ticketSubjectFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"ticketSubjectFilter",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a subject"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('ticketSubjectFilter', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"statusFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"statusFilter",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a status"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('statusFilter', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"createdFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"createdFilter",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"date"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('createdFilter', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"agentFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"agentFilter",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter an agent"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('agentFilter', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}}])})]],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }